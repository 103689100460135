<nav class="nav nav-custom sticky">
  <a class="nav-link" [ngClass]="{'active': activeToggle == ''}" (click)="statusFilter('')">All</a>
  <a class="nav-link" [ngClass]="{'active': activeToggle == 'In Use'}" (click)="statusFilter('In Use')">In Use</a>
  <a class="nav-link" [ngClass]="{'active': activeToggle == 'In Stock'}" (click)="statusFilter('In Stock')">In Stock</a>
  <a class="nav-link" [ngClass]="{'active': activeToggle == 'In Repair'}" (click)="statusFilter('In Repair')">In Repair</a>
  <a class="nav-link" [ngClass]="{'active': activeToggle == 'Missing'}" (click)="statusFilter('Missing')">Missing</a>
  <a class="nav-link" [ngClass]="{'active': activeToggle == 'Damaged'}" (click)="statusFilter('Damaged')">Damaged</a>
  <a class="nav-link" [ngClass]="{'active': activeToggle == 'Retired'}" (click)="statusFilter('Retired')">Retired</a>
</nav>
<div class="container-fluid p-24 ">
  <div class="row mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Inventory Details</h3>
      <p class="text-helper mb-md-0">Assets can be defined and managed in this page.</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class="" style="width:22.6875rem;">
          <div class="">
              <app-searchbar #search [searchlist]='searchlistdata' [searchplaceholder]='searchPlaceholder' (searchEmitter)="searchresults($event)"  (keywordsearchEmitter)="searchInventory($event)" [isLoading]="sLoading"></app-searchbar>
          </div>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle (click)="filterpanel=true">
            <i class="icon-filter-left fs-13"></i>
          </button>

        </div>
        <ng-container *ngIf="permissions?.a">

          <button class="btn flex-shrink-0 btn-outline-primary btn-icon btn-add" (click)="addheadercontent();invalidFormat=false;importPanel=true;">Import inventory</button>
          <button class="btn flex-shrink-0 btn-primary btn-icon btn-add" (click)="addInventory()"><i
              class="icon-plus"></i>Add inventory</button>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="row " style="height: calc(100vh - 15rem);" *ngIf="nodatafound && !loader; else all_data">
    <app-nodata-view class="vstack" [noDataImg]="'bg12.png'" [noDataText]="'NO Asset ADDED YET'" [hasPermission]="permissions?.a" (addData)="addheadercontent();editInventory=true">
    </app-nodata-view>
  </div>
  <div class="row row-16" *ngIf="loader">
    <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card11'" *ngFor="let dummy of ' '.repeat(10).split(''); let x = index"></app-common-loader>
  </div>
  <ng-template #all_data>
    <div class="" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true" (scrolled)="onScrollDown()" *ngIf="!loader">
      <div class="row row-16">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let item of landingData; let i = index">
          <div class="card card-custom1 h-100 card-hover">
            <div class="card-body p-0 d-flex align-items-start">
              <div class="d-flex view-section" (click)="viewInventory = true;currentId=item?.id">
                <div class="logo-img {{item.color_code}}" ng-init="getRandomColor()">{{this.appService.getFirstChar(item.name,2)}}</div>

                <div class="overflow-hidden">
                  <h6 class="mb-1 tips"> {{item?.name}} </h6>
                  <span class="tips-helper"> {{item?.name}} </span>
                  <div class="fs-10 text-uppercase fw-500 hstack gap-4 text-light-500">
                    <!-- <span>{{item?.asset_category_name}}</span><span>|</span><span>{{item?.asset_sub_category_name}}</span> -->
                    <span class="fs-10 text-uppercase fw-500 {{(item.is_active == true) ? 'text-success': 'text-danger'}}">{{(item.is_active == true) ? 'Active' : 'Deleted'}}</span>
                  </div>

                </div>
              </div>
              <div ngbDropdown class="d-inline-block dropdown-section"  *ngIf="(permissions?.e || permissions?.d) && item?.is_active==true">
                <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                  <i class="icon-three-dots-vertical "></i>
                </button>
                <ng-container>

                  <div ngbDropdownMenu class="bottom-left fs-14rem" aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem (click)="editInventoryData(item?.id)" *ngIf="permissions?.e">
                      <i class="icon-pencil-square me-1"></i> Edit
                    </button>
                    <button class="text-danger" ngbDropdownItem (click)="confirmDelete(item.id)" *ngIf="permissions?.d">
                      <i class="icon-trash  me-1"></i> Delete
                    </button>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="card-body flex-column justify-content-between gap-8">



              <div class="d-flex justify-content-between w-100 gap-8  fs-12">
                <span class="text-light-400 text-nowrap">Asset category </span>
                <span class="fw-500 text-trim text-end">{{item?.asset_category_name}}</span>
              </div>
              <div class="d-flex justify-content-between w-100 gap-8  fs-12">
                <span class="text-light-400 text-nowrap">Asset subcategory </span>
                <span class="fw-500 text-trim text-end">{{item?.asset_sub_category_name}}</span>
              </div>
              <div class="d-flex justify-content-between w-100 gap-8  fs-12">
                <span class="text-light-400 text-nowrap">Assigned on </span>
                <span class="fw-500 text-trim text-end">{{item?.assigned_on!=null && item?.assigned_on!='' && item?.assigned_on!='None'?this.appService.dateFormatDisplay(item?.assigned_on):'-'}}</span>
              </div>
              <ng-container *ngIf="item?.assigned_by==null || item?.assigned_by==''">
                <div class="d-flex justify-content-between w-100 gap-8  fs-10">
                    <div class="fs-12 text-muted">Loading</div>
                    <div class="dot-pulse me-16"></div>
                </div>
              </ng-container>
              <div class="row row-8 w-100" *ngIf="item?.assigned_to!=null && item?.assigned_to!='' && item?.assigned_by!=null && item?.assigned_by!=''">
                <div class="col ps-0">
                  <div class="d-flex align-items-center gap-8">

                    <img class="avatar-sm sq-32 avatar-circle" *ngIf="item?.assigned_by.profile_image != '' && item?.assigned_by.profile_image != null" src="{{ item?.assigned_by.profile_image }}" [ngbPopover]="popContentSingle" popoverClass="user-list single-user" triggers="hover" container="body"/>
                    <div class="avatar-sm sq-32 avatar-circle {{item?.assigned_by.color_code}}" *ngIf="(item?.assigned_by.profile_image == '' || item?.assigned_by.profile_image == null)" [ngbPopover]="popContentSingle" popoverClass="user-list single-user" triggers="hover" container="body">
                      <span>{{this.appService.getFirstChar(item?.assigned_by.first_name +" " +item?.assigned_by.last_name,2)}}</span>
                    </div>
                    <span class="text-light-400 text-nowrap fs-12">Assigned by </span>

                    <ng-template #popContentSingle>
                      <div class="user-details">
                        <span class="user-label">{{item?.assigned_by.first_name+"
                          "+(item?.assigned_by.middle_name!=null?item?.assigned_by.middle_name+" ":" ")+item?.assigned_by.last_name}}</span>
                        <span class="user-code">EMP CODE: {{ item?.assigned_by?.employee_code }}</span>
                      </div>
                    </ng-template>

                  </div>
                </div>
                <div class="col-1 flex-center">
                  <i class="bi bi-arrow-right lh-1"></i>

                </div>
                <div class="col pe-0">
                  <div class="d-flex align-items-center gap-8 justify-content-end">
                    <div class="text-light-400 fs-12  text-nowrap">
                      Assigned to
                    </div>
                      <img class="avatar-sm sq-32 avatar-circle" *ngIf="item?.assigned_to.profile_image != '' && item?.assigned_to.profile_image != null" src="{{ item?.assigned_to.profile_image }}" [ngbPopover]="signeAssignedto" popoverClass="user-list single-user" triggers="hover" container="body"/>
                      <div class="avatar-sm sq-32 avatar-circle {{item?.assigned_to.color_code}}" *ngIf="(item?.assigned_to.profile_image == '' || item?.assigned_to.profile_image == null)" [ngbPopover]="signeAssignedto" popoverClass="user-list single-user" triggers="hover" container="body">
                        <span>{{this.appService.getFirstChar(item?.assigned_to.first_name +" " +item?.assigned_to.last_name,2)}}</span>
                      </div>
                      <ng-template #signeAssignedto>
                        <div class="user-details">
                          <span class="user-label">{{item?.assigned_to.first_name+"
                            "+(item?.assigned_to.middle_name!=null?item?.assigned_to.middle_name+" ":" ")+item?.assigned_to.last_name}}</span>
                          <span class="user-code">EMP CODE: {{ item?.assigned_to?.employee_code }}</span>
                        </div>
                      </ng-template>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='editInventory === true'>
  <form class="side-panel-container" [formGroup]="inventoryForm"  autocomplete="off">

    <header class="side-panel-head">
      <h5>{{header}} Inventory </h5>
      <a class="toggle-panel" (click)="editInventory = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <app-view-loader *ngIf="viewLoader"></app-view-loader>

        <div class="row row-16" *ngIf="!viewLoader">
          <div class="col-12  ">
            <p class="text-uppercase fw-500 mb-0">Inventory details</p>
          </div>
          <div class="col-6">
            <label for="" class="form-label required">Asset name </label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon" ngbPopover='Enter the asset name' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
            <input type="text" class="form-control" formControlName="name" name="" id="name" value="" placeholder="{{this.messageService.placeholderdisp('asset name')}}" [ngClass]="{ 'is-invalid': ( f.name.dirty || f.name.touched)  &&  f.name.errors || f.name.errors?.exist}" (keyup)="keyUp('name',f.name.value)">
            <div *ngIf="( f.name.dirty || f.name.touched) && f.name.errors || f.name.errors?.exist" class="invalid-feedback">
                <div *ngIf="f.name.hasError('exist')">{{this.messageService.validationDisplay(f.name.value)}}</div>
                <div *ngIf="f.name.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="f.name.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                <div *ngIf="!(f.name.errors?.pattern) && f.name.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','100')}}</div>
            </div>
          </div>
          <div class="col-6">
            <label for="" class="form-label required">Asset code </label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon" ngbPopover='Enter the asset code' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
            <input type="text" class="form-control" formControlName="code" name="" id="code" value="" placeholder="{{this.messageService.placeholderdisp('asset code')}}" [ngClass]="{ 'is-invalid': ( f.code.dirty || f.code.touched)  &&  f.code.errors || f.code.errors?.exist}" (keyup)="keyUp('code',f.code.value)" >
            <div *ngIf="(f.code.dirty || f.code.touched) && f.code.errors || f.code.errors?.exist" class="invalid-feedback" (keyup)="keyUp('code',f.code.value)">
                <div *ngIf="f.code.hasError('exist')">{{this.messageService.validationDisplay(f.code.value)}}</div>
                <div *ngIf="f.code.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="f.code.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                <div *ngIf="!(f.code.errors?.pattern) && f.code.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','5')}}</div>
            </div>
          </div>
          <div class="col-6">
            <label for="" class="form-label required">Serial no </label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon" ngbPopover='Enter the serial number of the asset' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
            <input type="text" class="form-control" formControlName="serial_number" name="" id="serial_number" value="" placeholder="{{this.messageService.placeholderdisp('serial number')}}" [ngClass]="{ 'is-invalid': (f.serial_number.dirty ||f.serial_number.touched)  && f.serial_number.errors }" (keyup)="keyUp('serial_number',f.serial_number.value)">
            <div *ngIf="(f.serial_number.dirty ||f.serial_number.touched) &&f.serial_number.errors || f.serial_number.errors?.exist " class="invalid-feedback">
                <div *ngIf="f.serial_number.hasError('exist')">{{this.messageService.validationDisplay(f.serial_number.value)}}</div>
                <div *ngIf="f.serial_number.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="f.serial_number.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                <div *ngIf="!(f.serial_number.errors?.pattern) &&f.serial_number.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','30')}}</div>
            </div>
          </div>
          <div class="col-6">
            <label for="" class="form-label required">Category </label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon" ngbPopover='Asset category is a broad classification of assets within our organization. eg:"hardware"' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
            <ng-select class="form-ngselect" formControlName="asset_category" [items]="categoryDD" bindLabel="name" bindValue="id" [ngClass]="{ 'is-invalid': ( f.asset_category.touched) && f.asset_category.errors }" name=""  id="asset_category" placeholder="{{this.messageService.selectplaceholddisplay('category')}}" (ngModelChange)="getSubCategoryList()">
              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div class="d-inline-flex align-items-center">
                    <span> {{item?.name}}</span>
                  </div>
              </ng-template>
            </ng-select>
            <div *ngIf="( f.asset_category.touched) && f.asset_category.errors" class="invalid-feedback">
              <div *ngIf="f.asset_category.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
            </div>
          </div>
          <div class="col-6">
            <label for="" class="form-label required">Subcategory </label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon" ngbPopover='Select the corresponding subcategory based on the asset category selected eg"Laptop","desktop" etc.' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
            <ng-select class="form-ngselect"
            formControlName="asset_sub_category" [items]="subCategoryDD"
            bindLabel="name"
            bindValue="id" [ngClass]="{ 'is-invalid': ( f.asset_sub_category.touched) && f.asset_sub_category.errors }" name=""  id="asset_sub_category" placeholder="{{this.messageService.selectplaceholddisplay('subcategory')}}">
              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div class="d-inline-flex align-items-center">
                    <span> {{item?.name}}</span>
                  </div>
              </ng-template>
            </ng-select>
            <div *ngIf="( f.asset_sub_category.touched) && f.asset_sub_category.errors" class="invalid-feedback">
              <div *ngIf="f.asset_sub_category.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
            </div>
          </div>
          <div class=" col-12 form-row">
            <label for="" class="form-label">Description </label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon" ngbPopover='Enter a small description of the asset' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
            <textarea  [ngClass]="{ 'is-invalid': (f.description.dirty || f.description.touched) &&  f.description.errors}" rows="4" type="text" formControlName="description" class=" form-control " name="" id="" placeholder="{{this.messageService.descriptiontext('desc')}}">
            </textarea>
            <div *ngIf="(f.description.dirty || f.description.touched) && f.description.errors" class="invalid-feedback">
              <div *ngIf="f.description.errors.required">{{this.messageService.validationDisplay('required')}}</div>
              <div *ngIf="!(f.description.errors?.pattern) && f.description.errors.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}</div>
              <div *ngIf="f.description.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>

            </div>
          </div>
          <div class="col-6">
            <label for="" class="form-label ">Vendor </label>
            <input type="text" class="form-control" formControlName="vendor" name="" id="vendor" value="" placeholder="{{this.messageService.placeholderdisp('vendor')}}" [ngClass]="{ 'is-invalid': (f.vendor.dirty ||f.vendor.touched)  && f.vendor.errors }" >
            <div *ngIf="(f.vendor.dirty ||f.vendor.touched) &&f.vendor.errors " class="invalid-feedback">

            <div *ngIf="f.vendor.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
            <div *ngIf="f.vendor.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
            <div *ngIf="!(f.vendor.errors?.pattern) &&f.vendor.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','30')}}</div>
            </div>
          </div>
          <div class="col-6">
            <label for="" class="form-label ">GST no. </label>
            <input type="text" class="form-control" formControlName="gst_number" name="" id="gst_number" value="" placeholder="{{this.messageService.placeholderdisp('GST number')}}" [ngClass]="{ 'is-invalid': (f.gst_number.dirty ||f.gst_number.touched)  && f.gst_number.errors }" >
            <div *ngIf="(f.gst_number.dirty ||f.gst_number.touched) &&f.gst_number.errors " class="invalid-feedback">
              <div *ngIf="f.gst_number.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
              <div *ngIf="f.gst_number.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
            </div>
          </div>
          <div class="col-6">
            <label for="" class="form-label "> Invoice number </label>
            <input type="text" class="form-control" formControlName="invoice_number" name="" id="invoice_number" value="" placeholder="{{this.messageService.placeholderdisp('invoice number')}}" [ngClass]="{ 'is-invalid': (f.invoice_number.dirty ||f.invoice_number.touched)  && f.invoice_number.errors }" >
            <div *ngIf="(f.invoice_number.dirty ||f.invoice_number.touched) &&f.invoice_number.errors " class="invalid-feedback">

            <div *ngIf="f.invoice_number.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
            <div *ngIf="f.invoice_number.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
            <div *ngIf="!(f.invoice_number.errors?.pattern) &&f.invoice_number.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','30')}}</div>
            </div>
          </div>
          <div class="col-6">
            <label for="" class="form-label ">Invoice date </label>
            <div class="input-group form-icon icon-right" [ngClass]="{ 'is-invalid': (f.invoice_date.touched || f.invoice_date.dirty) && f.invoice_date.errors}">
              <input (click)="datePicker1.open()" [matDatepickerFilter]="dateFilter" id="invoice_date" autocomplete="off" placeholder="{{this.messageService.placeholderdisp('date')}}" class="form-control" [ngClass]="{ 'is-invalid': ( f.invoice_date.touched || f.invoice_date.dirty) && f.invoice_date.errors}" matInput formControlName="invoice_date" [matDatepicker]="datePicker1" (dateChange)="setWarrentyExpiry()">
              <i class="icon-calendar pointer" (click)="datePicker1.open()"></i>
              <mat-datepicker at-datepicker touchUi="false" #datePicker1></mat-datepicker>
            </div>
            <div *ngIf="( f.invoice_date.touched || f.invoice_date.dirty) && f.invoice_date.errors" class="invalid-feedback">
              <div *ngIf="f.invoice_date.errors.required">{{this.messageService.validationDisplay('required')}}</div>
            </div>
          </div>

          <div class="col-6">
            <label for="" class="form-label ">Payment type </label>
            <app-master-select [ngClass]="{ 'is-invalid': ( f.payment_type?.touched) && f.payment_type?.errors }" [placeholder] ="paymentPlaceholder" id="payment_type" [submitted]="true" [selectedItem]="f.payment_type?.value" formControlName="payment_type" [type]="'payment_mode'" [isrequired]="true" ></app-master-select>
            <div *ngIf="( f.payment_type?.touched) && f.payment_type?.errors" class="invalid-feedback" >
                <div *ngIf="f.payment_type.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
            </div>
          </div>
          <div class="col-6">
            <label for="" class="form-label ">Currency </label>
            <app-master-select [ngClass]="{ 'is-invalid': ( f.currency?.touched) && f.currency?.errors }" [placeholder]="currencyPlaceHolder" id="currency" [submitted]="true" [selectedItem]="f.currency?.value" formControlName="currency" [type]="currency" [isrequired]="true"></app-master-select>
              <div *ngIf="( f.currency?.touched) && f.currency?.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.currency.errors?.required">
                      {{this.messageService.validationDisplay('required')}}
                  </div>
              </div>

          </div>
          <div class="col-6">
            <label for="" class="form-label ">Cost (per item) </label>
            <input type="text" class="form-control" formControlName="cost_per_item" name="" id="cost_per_item" value="" placeholder="{{this.messageService.placeholderdisp('cost (per item)')}}" [ngClass]="{ 'is-invalid': (f.cost_per_item.dirty ||f.cost_per_item.touched)  && f.cost_per_item.errors }" >
              <div *ngIf="(f.cost_per_item.dirty ||f.cost_per_item.touched) &&f.cost_per_item.errors " class="invalid-feedback">

              <div *ngIf="f.cost_per_item.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
              <div *ngIf="f.cost_per_item.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
              <div *ngIf="!(f.cost_per_item.errors?.pattern) &&f.cost_per_item.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','10')}}</div>
              </div>
          </div>
          <div class="col-6">
            <label for="" class="form-label ">Other cost </label>
            <input type="text" class="form-control" formControlName="other_cost" name="" id="other_cost" value="" placeholder="{{this.messageService.placeholderdisp('other cost')}}" [ngClass]="{ 'is-invalid': (f.other_cost.dirty ||f.other_cost.touched)  && f.other_cost.errors }" >
            <div *ngIf="(f.other_cost.dirty ||f.other_cost.touched) &&f.other_cost.errors " class="invalid-feedback">

              <div *ngIf="f.other_cost.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
              <div *ngIf="f.other_cost.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
              <div *ngIf="!(f.other_cost.errors?.pattern) &&f.other_cost.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','10')}}</div>
            </div>
          </div>
          <div class="col-6">
            <label for="" class="form-label ">GST amount </label>
            <input type="text" class="form-control" formControlName="gst" name="" id="gst" value="" placeholder="{{this.messageService.placeholderdisp('GST amount')}}" [ngClass]="{ 'is-invalid': (f.gst.dirty ||f.gst.touched)  && f.gst.errors }" >
            <div *ngIf="(f.gst.dirty ||f.gst.touched) &&f.gst.errors " class="invalid-feedback">
              <div *ngIf="f.gst.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
              <div *ngIf="f.gst.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
              <div *ngIf="!(f.gst.errors?.pattern) &&f.gst.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','10')}}</div>
            </div>
          </div>
          <div class="col-6">
            <label for="" class="form-label ">Manufacturing date </label>
            <div class="input-group form-icon icon-right" [ngClass]="{ 'is-invalid': (f.manufacturing_date.touched || f.manufacturing_date.dirty) && f.manufacturing_date.errors}">
              <input (click)="datePicker2.open()" [matDatepickerFilter]="dateFilter" id="manufacturing_date" autocomplete="off" placeholder="{{this.messageService.placeholderdisp('date')}}" class="form-control" [ngClass]="{ 'is-invalid': ( f.manufacturing_date.touched || f.manufacturing_date.dirty) && f.manufacturing_date.errors}" matInput formControlName="manufacturing_date" [matDatepicker]="datePicker2" >
              <i class="icon-calendar pointer" (click)="datePicker2.open()"></i>
              <mat-datepicker at-datepicker touchUi="false" #datePicker2></mat-datepicker>
            </div>
            <div *ngIf="( f.manufacturing_date.touched || f.manufacturing_date.dirty) && f.manufacturing_date.errors" class="invalid-feedback">
              <div *ngIf="f.manufacturing_date.errors.required">{{this.messageService.validationDisplay('required')}}</div>
            </div>
          </div>

          <!-- New Changes -->
          <div class="col-12">
            <label class="form-check p-0 d-flex" for="check1">
                <input type="checkbox" name="check" class="form-check-input ms-0" formControlName="warranty_notification_enable" id="war_enable" (change)="checkValidator('warranty_notification_days')">
                <label class="form-check-label" for="war_enable">Enable warranty expiry notification</label>
            </label>
          </div>

          <ng-container *ngIf="f.warranty_notification_enable.value == true">
            <div class="col-12">
              <label for="" class="form-label ">How many days before the warranty expiration date, the notification should be triggered to the task owner?
                <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon" ngbPopover="Enter how many days before an asset's warranty expiry date the task owner should receive a notification." popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
              </label>
              <input type="text" name="" class="form-control" placeholder="{{this.messageService.placeholderdisp('days')}} " id="" formControlName="warranty_notification_days" [ngClass]="{ 'is-invalid' : ( inventoryForm.get('warranty_notification_days')?.dirty || inventoryForm.get('warranty_notification_days')?.touched) && inventoryForm.get('warranty_notification_days')?.errors}">
              <div *ngIf="(inventoryForm.get('warranty_notification_days')?.touched || inventoryForm.get('warranty_notification_days')?.dirty) && inventoryForm.get('warranty_notification_days')?.errors " class="invalid-feedback">
                <div *ngIf="inventoryForm.get('warranty_notification_days')?.errors?.required">{{this.messageService.validationDisplay('required')}}
                </div>
                <div *ngIf="inventoryForm.get('warranty_notification_days')?.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}
                </div>
                <div *ngIf="!(inventoryForm.get('warranty_notification_days')?.errors?.pattern) && inventoryForm.get('warranty_notification_days')?.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','2')}}
                </div>
              </div>
            </div>
          </ng-container>

          <div class="col-6">
            <label for="" class="form-label ">Warranty period (month) </label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon" ngbPopover='Specify the warranty period for the asset: enter the duration in months.' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
            <input type="text" class="form-control" formControlName="warranty_period" name="" id="warranty_period" value="" placeholder="{{this.messageService.placeholderdisp('Warranty period (month)')}}" [ngClass]="{ 'is-invalid': (f.warranty_period.dirty ||f.warranty_period.touched)  && f.warranty_period.errors }" (keyup)="setWarrentyExpiry()">
            <div *ngIf="(f.warranty_period.dirty ||f.warranty_period.touched) &&f.warranty_period.errors " class="invalid-feedback">

              <div *ngIf="f.warranty_period.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
              <div *ngIf="f.warranty_period.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
              <div *ngIf="!(f.warranty_period.errors?.pattern) && f.warranty_period.errors?.maxlength">{{this.messageService.fieldlengthvalidation('num','3')}}</div>
            </div>
          </div>
          <div class="col-6">
            <label for="" class="form-label ">warranty expiry </label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon" ngbPopover='Choose the date when the warranty of the asset ends.' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
            <div class="input-group form-icon icon-right" [ngClass]="{ 'is-invalid': (f.warranty_expiry_date.touched || f.warranty_expiry_date.dirty) && f.warranty_expiry_date.errors}">
              <input (click)="datePicker3.open()" id="warranty_expiry_date" [min]="warrentyMinDate" [max]="warrentyMaxDate" autocomplete="off" placeholder="{{this.messageService.placeholderdisp('date')}}" class="form-control" [ngClass]="{ 'is-invalid': ( f.warranty_expiry_date.touched || f.warranty_expiry_date.dirty) && f.warranty_expiry_date.errors}" matInput formControlName="warranty_expiry_date" [matDatepicker]="datePicker3" >
              <i class="icon-calendar pointer" (click)="datePicker3.open()"></i>
              <mat-datepicker at-datepicker touchUi="false" #datePicker3></mat-datepicker>
            </div>
            <div *ngIf="( f.warranty_expiry_date.touched || f.warranty_expiry_date.dirty) && f.warranty_expiry_date.errors" class="invalid-feedback">
              <div *ngIf="f.warranty_expiry_date.errors.required">{{this.messageService.validationDisplay('required')}}</div>
            </div>
          </div>
          <div class="col-6">
            <label for="" class="form-label ">Last audit date </label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon" ngbPopover='select the date of the most recent audit.' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
            <div class="input-group form-icon icon-right" [ngClass]="{ 'is-invalid': (f.last_audit_date.touched || f.last_audit_date.dirty) && f.last_audit_date.errors}">
              <input (click)="datePicker4.open()" [matDatepickerFilter]="dateFilter" id="last_audit_date" autocomplete="off" placeholder="{{this.messageService.placeholderdisp('date')}}" class="form-control" [ngClass]="{ 'is-invalid': ( f.last_audit_date.touched || f.last_audit_date.dirty) && f.last_audit_date.errors}" matInput formControlName="last_audit_date" [matDatepicker]="datePicker4" >
              <i class="icon-calendar pointer" (click)="datePicker4.open()"></i>
              <mat-datepicker at-datepicker touchUi="false" #datePicker4></mat-datepicker>
            </div>
            <div *ngIf="( f.last_audit_date.touched || f.last_audit_date.dirty) && f.last_audit_date.errors" class="invalid-feedback">
              <div *ngIf="f.last_audit_date.errors.required">{{this.messageService.validationDisplay('required')}}</div>
            </div>
          </div>
          <div class="col-6">
            <label for="" class="form-label ">End of life </label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon" ngbPopover='Enter the date when this product is no longer available.' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
            <div class="input-group form-icon icon-right" [ngClass]="{ 'is-invalid': (f.end_of_life_date.touched || f.end_of_life_date.dirty) && f.end_of_life_date.errors}">
              <input (click)="datePicker5.open()" [matDatepickerFilter]="dateFilter" id="end_of_life_date" autocomplete="off" placeholder="{{this.messageService.placeholderdisp('date')}}" class="form-control" [ngClass]="{ 'is-invalid': ( f.end_of_life_date.touched || f.end_of_life_date.dirty) && f.end_of_life_date.errors}" matInput formControlName="end_of_life_date" [matDatepicker]="datePicker5" >
              <i class="icon-calendar pointer" (click)="datePicker5.open()"></i>
              <mat-datepicker at-datepicker touchUi="false" #datePicker5></mat-datepicker>
            </div>
            <div *ngIf="( f.end_of_life_date.touched || f.end_of_life_date.dirty) && f.end_of_life_date.errors" class="invalid-feedback">
              <div *ngIf="f.end_of_life_date.errors.required">{{this.messageService.validationDisplay('required')}}</div>
            </div>
          </div>
          <div class="col-6">
            <label for="" class="form-label required">Status </label>
            <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon" ngbPopover='Select the current status from the dropdown list: In Use, In Stock, In Repair, Missing, Retired, or Damaged.' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
            <app-master-select [ngClass]="{ 'is-invalid': ( f.status?.touched) && f.status?.errors }" [placeholder]="statusPlaceHolder" id="inventory_status" [submitted]="true" [selectedItem]="f.status?.value" formControlName="status" [type]="'inventory_status'" [isrequired]="true" (ngModelChange)="addValidators()"></app-master-select>
            <div *ngIf="( f.status?.touched) && f.status?.errors" class="invalid-feedback">
              <div *ngIf="f.status.errors?.required">
                  {{this.messageService.validationDisplay('required')}}
              </div>
            </div>
          </div>
          <ng-container *ngIf="f.status.value!='In Stock' && f.status.value!='Retired'">

            <div class="col-md-6 ">
              <label for="name" class="form-label {{f.status.value=='In Use'?'required':''}}">Assigned to </label>
              <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Assigned to')}}"
                formControlName="employee" [items]="empDD" class="form-ngselect" bindValue="id"
                bindLabel="fullname" [ngClass]="{ 'is-invalid': (f.employee.touched) && f.employee.errors || f.employee.errors?.releivedError}" (change)="assignedToChangeFunction($event)">
                <ng-template ng-label-tmp let-item="item">
                  <span class="ng-value-label">
                    <div class="d-inline-flex align-items-center">
                      <div *ngIf="item.profile_image ==  '' || item.profile_image ==  null"
                        class="avatar-sm sq-24 rounded-circle {{item.color_code}} me-1">
                        <span>{{this.appService.getFirstChar(item.fullname,2)}}</span>
                      </div>
                      <img class="avatar-sm sq-24 img-fluid rounded-circle me-1"
                        *ngIf="item.profile_image !=  '' && item.profile_image !=  null"
                        [src]="item.profile_image">
                      {{item.fullname}}
                    </div>
                  </span> </ng-template>
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <div class="d-inline-flex  align-items-center">
                    <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"
                      *ngIf="item.profile_image !=  '' && item.profile_image != null"
                      src="{{item.profile_image}}">
                    <span *ngIf="item.profile_image ==  ''|| item.profile_image == null"
                      class="avatar-sm sq-32 me-8 rounded-circle {{item.color_code}}">
                      <span>
                        {{this.appService.getFirstChar(item.first_name+" "+item.last_name,2)}}</span>
                    </span>
                    {{item.fullname}}
                  </div>
                </ng-template>
              </ng-select>
              <div *ngIf="( f.employee.touched) && f.employee.errors || f.employee.errors?.releivedError" class="invalid-feedback">
                <div *ngIf="f.employee?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="f.employee.errors?.releivedError">Employee is already {{emStatus}}.</div>
              </div>
            </div>

            <div class="col-6">
              <label for="" class="form-label {{f.status.value=='In Use'?'required':''}}">Assigned on </label>
              <div class="input-group form-icon icon-right" [ngClass]="{ 'is-invalid': (f.assigned_on.touched || f.assigned_on.dirty) && f.assigned_on.errors}">
                <input (click)="datePicker6.open()" [matDatepickerFilter]="dateFilter" id="assigned_on" autocomplete="off" placeholder="{{this.messageService.placeholderdisp('date')}}" class="form-control" [ngClass]="{ 'is-invalid': ( f.assigned_on.touched || f.assigned_on.dirty) && f.assigned_on.errors}" matInput formControlName="assigned_on" [matDatepicker]="datePicker6" >
                <i class="icon-calendar pointer" (click)="datePicker6.open()"></i>
                <mat-datepicker at-datepicker touchUi="false" #datePicker6></mat-datepicker>
              </div>
              <div *ngIf="( f.assigned_on.touched || f.assigned_on.dirty) && f.assigned_on.errors" class="invalid-feedback">
                <div *ngIf="f.assigned_on.errors.required">{{this.messageService.validationDisplay('required')}}</div>
              </div>
            </div>
          </ng-container>
          <div class="col-12">
            <label class="form-label">Add attachments </label>
            <div class="card card-c2 px-16">
              <div class="card-body px-0 py-16">
                <label class="upload-btn "  [ngClass]="{ 'is-invalid': (fileError == true)  }">
                  <div class="upload-text">Upload documents <span class="text-primary">here
                    <app-file-upload [crop]="true" [round]="false"  [width]="504" [FileType]="'.png,.jpeg,.jpg'" (dataURL)="getURL($event)" (fileformat)="getfileformat($event)" (validsize)="getvalidsize($event)" (loader)="getloader($event)"></app-file-upload>
                    </span>
                    <div class="fs-12 text-light-500 mt-2">Supports JPG, PNG, JPEG</div>
                  </div>
                  <input type="file" class="form-control" placeholder="" hidden>
                </label>
                <div *ngIf="(fileError == true)" class="invalid-feedback">
                  <div>{{fileErrorMsg}}</div>
                </div>
              </div>
              <div class="card-body px-0 py-16"  *ngIf="uploadedFile.length">
                <div class="row row-8">
                  <div class="col-6" *ngFor="let item of uploadedFile let i = index">

                    <div class="card card-c2  bg-secondary">
                      <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                        <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                        <ng-container *ngIf="uploadedFile[i].url!=''">
                        <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                          <span class="tips text-uppercase  fw-700 text-trim">{{this.appService.getDocName(uploadedFile[i].url)}}</span>
                          <span class="tips-helper ">{{this.appService.getDocName(uploadedFile[i].url)}}</span>
                          <div class="d-flex gap-8">
                            <div class="link-primary" (click)="viewDocument(uploadedFile[i].url)">
                              <i class="bi bi-eye me-1"></i>View
                            </div>
                            <div class="link-primary" (click)="this.appService.downloadFile(uploadedFile[i].url,this.appService.getDocName(uploadedFile[i].url))">
                              <i class="bi bi-download me-1"></i>Download
                            </div>
                          </div>
                        </div>
                        <i class="icon-trash  ms-auto flex-center link-danger" (click)="removeSelectedFile(i)"></i>
                       </ng-container>
                       <div class="  flex-center text-center"  *ngIf="uploadedFile[i].loader">
                        <div class="fs-12 text-muted">Loading </div>
                        <div class="ms-4 dot-pulse"></div>

                       </div>
                      </div>
                    </div>
                    <!-- <div class="p-12 bg-secondary radius-8 d-flex align-items-center">
                      <i class="bi bi-file-earmark-text-fill me-16 flex-center text-accent2 fs-40"></i>
                      <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden w-100">
                        <ng-container *ngIf="uploadedFile[i].url!=''">
                          <span class="tips text-uppercase fs- fw-700 text-trim">{{this.appService.getDocName(uploadedFile[i].url)}}</span>
                          <span class="tips-helper ">{{this.appService.getDocName(uploadedFile[i].url)}}</span>
                        </ng-container>
                        <div class="d-flex align-items-center justify-content-between overflow-hidden"  *ngIf="uploadedFile[i].loader" >
                          <div class="fs-12 text-muted">Loading</div>
                          <div class="dot-pulse me-16"></div>
                        </div>
                      </div>
                      <span class="link-danger fw-500 flex-shrink-0" (click)="removeSelectedFile(i)" *ngIf="uploadedFile[i].url!=''">
                        <i class="icon-trash ms-8 "></i>
                      </span>
                    </div> -->
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="col-12">
            <div class="card card-c2 p-16">
              <div class="row row-16">
                <div class="col-12">
                  <div class="title">Managed by</div>

                </div>

                  <div class="col-12">
                    <label for="" class="form-label required">This asset is managed by </label>
                    <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-11 tooltip-icon" ngbPopover='select the appropriate OU.' popoverClass="popover-default popover-info" placement="bottom" container="body" triggers="hover"></i></span>
                    <ng-select [readonly]="disabled" class="form-ngselect" [attr.disabled]="disabled? true : null" [ngClass]="{ 'is-invalid': (f.applicable.touched) &&  f.applicable.errors}" (change)="changeApplicable(0)"  formControlName="applicable" placeholder="{{this.messageService.selectplaceholddisplay('novalue')}}">
                        <ng-option *ngFor="let app of applicable" [value]="app.name">{{app.name}}</ng-option>
                    </ng-select>

                    <div *ngIf="(f.applicable.touched) && f.applicable.errors" class="invalid-feedback"><div *ngIf="f.applicable.errors.required">{{this.messageService.validationDisplay('required')}}</div></div>

                  </div>

              <ng-container *ngIf="f.applicable.value!=null">

                <div class="col-12" *ngIf="list.length">
                  <label for="" class="form-label required">{{f.applicable.value}}</label>
                  <ng-select [readonly]="disabled" [ngClass]="{ 'is-invalid': (f.assignees.touched) &&  f.assignees.errors}" placeholder="{{this.messageService.selectplaceholddisplay('novalue')}}" formControlName="assignees" [items]="list" [multiple]="true" groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false" bindValue="id" class="multiselect" [attr.disabled]="disabled? true : null" (change) ="saveClicked=true" >
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <div class="d-inline-flex align-items-center">
                        <input id="item-{{index}}" class="me-8" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                        <img class="avatar-sm sq-24 img-fluid rounded-circle me-8"  *ngIf="item.logo !=  null && item.logo !=  '' && f.applicable.value  ==  'Company'" src="{{item.logo}}">
                        <span *ngIf="(item.logo ==  null || item.logo ==  '') && (f.applicable.value  ==  'Company')" class="avatar-sm sq-24 me-8 rounded-circle bg{{index%5}}" ><span>{{this.appService.getFirstChar(item.name,1)}}</span></span>

                        <img class="avatar-sm sq-24 img-fluid rounded-circle me-8"  *ngIf="item.profile_image !=  null && item.profile_image !=  '' && f.applicable.value  ==  'Employee'"  src="{{item.profile_image}}">
                        <span *ngIf="(item.profile_image ==  null || item.profile_image ==  '') && (f.applicable.value  ==  'Employee')" class="avatar-sm sq-24 me-8 rounded-circle {{item.color_code}}" ><span>{{this.appService.getFirstChar(item.first_name+" "+item.last_name,2)}}</span></span>
                        <span class="">               {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name)  }}                    </span>
                      </div>
                    </ng-template>
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="ng-value" *ngIf="f.assignees.value.length">
                            <span class="ng-value-label">{{f.assignees.value.length}} {{f.applicable.value}} selected.</span>
                        </div>
                    </ng-template>
                </ng-select>

                <div *ngIf="(f.assignees.touched) && f.assignees.errors" class="invalid-feedback"><div *ngIf="f.assignees.errors.required">{{this.messageService.validationDisplay('required')}}</div></div>
                </div>
                <div class="col-12">
                <ul class="selected-values " *ngIf="f.assignees.value.length != 0">
                    <li class="ng-value" *ngFor="let item of f.assignees.value, let m =index">
                        <ng-container *ngFor="let insideitem of list, let k =index">
                        <span class="ng-value-label" *ngIf="insideitem['id']==item">
                          <div class="d-inline-flex align-items-center">
                            <img class="avatar-sm sq-24 img-fluid rounded-circle"  *ngIf="insideitem.profile_image !=  '' && insideitem.profile_image !=  null && f.applicable.value  ==  'Employee'" src="{{insideitem.profile_image}}">
                            <span *ngIf="(insideitem.profile_image ==  null || insideitem.profile_image ==  '') && (f.applicable.value  ==  'Employee')" class="avatar-sm sq-24 me-8 rounded-circle {{insideitem.color_code}}" >
                                <span>
                                {{this.appService.getFirstChar(insideitem.first_name+" "+insideitem.last_name,2)}}</span>
                            </span>

                            <img class="avatar-sm sq-24 img-fluid rounded-circle"  *ngIf="insideitem.logo !=  '' && insideitem.logo !=  null && f.applicable.value  ==  'Company'" src="{{insideitem.logo}}">
                            <span *ngIf="(insideitem.logo ==  null || insideitem.logo ==  '') && (f.applicable.value  ==  'Company')" class="avatar-sm sq-24 rounded-circle bg{{k%5}}" >
                                <span>
                                {{this.appService.getFirstChar(insideitem.name,1)}}</span>
                            </span>
                            <span class=""> {{ (insideitem['name'].length>15)? (insideitem['name'] | slice:0:15)+'..':(insideitem['name'])  }}</span>
                         </div>
                        </span>
                        </ng-container>
                        <span *ngIf="disabled == false" class="ng-value-icon right" aria-hidden="true" (click)="clear(m,0)"><i class="icon-close"></i></span>
                    </li>
                </ul>

                </div>
              </ng-container>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" [disabled]="inventoryForm.invalid || saveClick" (click)="saveInventory()">
        Save
      </button>
    </footer>
  </form>
</div>
<app-inventory-view *ngIf="viewInventory" [viewInventory]="viewInventory" [isEdit]="permissions?.e" [id]="currentId" (closeModel)="viewInventory=false" (viewHistory)="viewHistory()" (edit)="editInventoryData($event)"></app-inventory-view>


<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='viewInventoryHistory === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details </h5>
      <a class="toggle-panel" (click)="viewInventoryHistory = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <app-view-loader *ngIf="historyLoader"></app-view-loader>
        <div class="row pt-5 flex-center text-center" *ngIf="viewNodata && !historyLoader">
          <app-nodata-view [noDataText]="'No Inventory History Available'" [hasPermission]="false" >
          </app-nodata-view>
        </div>
        <div class="row row-16" *ngIf="!historyLoader && !viewNodata">
          <div class="col-12 ">
            <p class="text-uppercase fw-500 mb-0">Inventory History</p>
          </div>
          <div class="col-12" *ngFor="let data of historyData">
            <div class="card details-card shadow-0 px-24 pb-24 pt-16">
              <div class="row row-16 " >
                <div class="col-12 ">
                  <p class="title mb-8">Details</p>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Assigned to</div>
                  <div class="d-flex align-items-center">
                    <img
                      *ngIf="(data?.assigned_to!=null && (data?.assigned_to_profile_image !=  '' && data?.assigned_to_profile_image !=  null))"
                      class="user-img img-fluid rounded-circle sq-32" src="{{data?.assigned_to_profile_image}}">
                    <div
                      *ngIf="(data?.assigned_to!=null && (data?.assigned_to_profile_image ==  '' || data?.assigned_to_profile_image ==  null))"
                      class="avatar-circle fs-14 fw-500 {{data?.assigned_to_color_code}} sq-32 rounded-circle">
                      <span>{{this.appService.getFirstChar(data?.assigned_to,2)}}</span>
                    </div>

                    <div class="detail-desc p-2">
                      {{data?.assigned_to!=null?(data?.assigned_to+" ("+data?.assigned_to_employee_code+")"):'-'}}
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Assigned on</div>
                  <div class="detail-desc">{{this.appService.dateFormatDisplay(data?.assigned_on)}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Assigned by</div>
                  <!-- <div class="detail-desc">{{data?.assigned_by}}</div> -->
                  <div class="d-flex align-items-center">
                    <img
                      *ngIf="(data?.assigned_by!=null && (data?.assigned_by_profile_image !=  '' && data?.assigned_by_profile_image !=  null))"
                      class="user-img img-fluid rounded-circle sq-32" src="{{data?.assigned_by_profile_image}}">
                    <div
                      *ngIf="(data?.assigned_by!=null && (data?.assigned_by_profile_image ==  '' || data?.assigned_by_profile_image ==  null))"
                      class="avatar-circle fs-14 fw-500 {{data?.assigned_by_color_code}} sq-32 rounded-circle">
                      <span>{{this.appService.getFirstChar(data?.assigned_by,2)}}</span>
                    </div>

                    <div class="detail-desc p-2">
                      {{data?.assigned_by!=null?(data?.assigned_by+" ("+data?.assigned_by_employee_code+")"):'-'}}
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Returned on</div>
                  <div class="detail-desc">{{data?.returned_on!=null && data?.returned_on!='None' && data?.returned_on!=''?this.appService.dateFormatDisplay(data?.returned_on):'-'}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Recovery by</div>
                  <!-- <div class="detail-desc">{{data?.recovery_by!=null && data?.recovery_by!=""?data?.recovery_by:'-'}}</div> -->
                  <div class="d-flex align-items-center" *ngIf="data?.recovery_by!=null && data?.recovery_by!='';else recovery">
                    <img
                      *ngIf="(data?.recovery_by!=null && (data?.recovery_by_profile_image !=  '' && data?.recovery_by_profile_image !=  null))"
                      class="user-img img-fluid rounded-circle sq-32" src="{{data?.recovery_by_profile_image}}">
                    <div
                      *ngIf="(data?.recovery_by!=null && (data?.recovery_by_profile_image ==  '' || data?.recovery_by_profile_image ==  null))"
                      class="avatar-circle fs-14 fw-500 {{data?.recovery_by_color_code}} sq-32 rounded-circle">
                      <span>{{this.appService.getFirstChar(data?.recovery_by,2)}}</span>
                    </div>

                    <div class="detail-desc p-2">
                      {{data?.recovery_by!=null?(data?.recovery_by+" ("+data?.recovery_by_employee_code+")"):'-'}}
                    </div>
                  </div>
                  <ng-template #recovery>
                    <div class="detail-desc">-</div>
                  </ng-template>
                </div>

              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

  </div>
</div>

<div class="side-panel" style="--sidepanel-width: 56.875rem;" [class.side-pane-active]='importPanel === true'>
  <div class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
          <h5>Import Inventory</h5>
          <a class="toggle-panel" (click)="importPanel = false"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;">
          <div class="container-fluid p-32">
            <div class="row row-24 mb-24">
              <div class="col-12">
                  <div class="card card-c2 p-24 ">
                      <div class="title mb-24">Inventory</div>

                      <div class="row" *ngIf="fileName">
                        <div class="col-md-6">
                          <div class="card card-c2 bg-secondary  mb-24" *ngIf="fileName!=''">
                            <div class="d-flex p-24 ">
                                <i class="bi bi-file-earmark-text-fill text-accent2 me-8"></i>
                                <span class="text-trim fw-500">{{fileName}}</span>
                                <span class="ms-auto text-accent1 text-nowrap me-8">{{fileSize}}</span>
                                <span class="link-danger fw-500 flex-shrink-0" (click)="clearData()" ><i class="bi bi-trash ms-8 "></i></span>
                            </div>
                          </div>

                        </div>
                      </div>
                    <label for="upload"  [class.disabled]="fileName != ''" style="max-width: 18.375rem;" class="btn btn-outline-primary radius-2 px-5 {{invalidFormat?'is-invalid':''}}">
                        <input [disabled]="fileName != ''" type="file" name="" onclick="this.value = null" (change)="onFileChange($event)" id="upload" hidden>Import Inventory
                    </label>
                  <span class="invalid-feedback" *ngIf="invalidFormat">{{this.messageService.validateFileFormat('xlsx/csv')}}</span>

                  </div>
              </div>
              <div class="col-12">
                  <div class="card card-c2 p-24">
                      <div class="title mb-24">Instructions </div>
                      <ol class="fs-14 ps-16 mb-0 li-24">
                          <li>Download the <a class="link-primary" href="assets/docs/sample-inventory-import.xlsx"> <i class="icon-download me-1"></i> Sample Excel
                          template</a></li>
                          <li>To import the inventory details, click on the Import Inventory button.</li>
                          <li>Choose the file to be imported.</li>
                          <li>The file chosen should be in (.xls/.csv)format only.</li>

                      </ol>
                  </div>
              </div>
          </div>

          </div>
      </div>
      <footer class="side-panel-footer gap-32 pt-16">
        <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="fileName == '' || isUploaded" type="button" (click)="uploadData();isUploaded=true">Upload</button>
      </footer>
  </div>

</div>

<div class="modal  modal-alert {{errorToggle == true ? 'show': ''}}"  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
          <div class="modal-header gap-16">
          <i class="bi bi-exclamation-triangle text-danger"></i>
          <h4 class="modal-title">Error Occured</h4>
          </div>
          <div class="modal-body">
                  <p class="mb-0">{{errorMsg}}</p>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-outline-primary btn-sm" (click)="errorToggle = false;">Close</button>
          </div>
      </div>
  </div>
</div>

<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
          <div class="modal-header gap-16rem">
              <i class="bi bi-exclamation-triangle text-danger"></i>
              <h4 class="modal-title">Delete Inventory?</h4>
          </div>
          <div class="modal-body">
              <p class="mb-0">{{this.messageService.Deletemsgdisplay('Inventory')}}</p>
          </div>
          <div class="modal-footer"  *ngIf="permissions">
              <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
                  (click)="deleteToggle = false">Cancel</button>
              <button   type="button" class="btn btn-danger btn-sm text-uppercase"  [disabled]="deleteClicked" (click)="deleteInventory()">Delete</button>
          </div>
      </div>
  </div>
</div>
<app-upload-progress-bar *ngIf=" isDataUpload !=0" [progress]="percentage| number:'1.0-0'" [iscompleted]="iscompleted"
  [isProgressStart]="isProgressStart" [heading]="'Uploading'"></app-upload-progress-bar>
<app-error-download [fileName]="'Inventory Error'" [successDataCount]="successDataCount" [downloadMsg]="downloadMsg"
  [failedData]="exportData" [modalHeading]="'Upload Inventory'" (closePanel)="downloadMsg = false;clearData()"
  [successMsg]="successMsg" [errorMsg]="errorMsg"> </app-error-download>

 <app-asset-filter *ngIf="filterpanel" [filterpanel]="filterpanel" [from]="'asset'" (filterData)="applyFilter($event)" (close)="closeFilter()" (reset)="resetfilter()" [dataFilter]="datafilter" [filterForm]="filterForm">

 </app-asset-filter>

 <!-- un supported view format alert -->
<app-info-popup *ngIf="alertToggle" (modal)="alertToggle = false" [infoToggle]="alertToggle" [infoMsg]="alertMsg"></app-info-popup>

<!-- Viewing the Document -->
<ng-container *ngIf="modalToggle== true">
  <app-view-file (modal)="modalToggle = false" [modalToggle]="modalToggle" [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName1"></app-view-file>
</ng-container>

