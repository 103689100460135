<ng-container>
    <nav class="nav nav-custom sticky">
      <a
        class="nav-link"
        [ngClass]="{ active: activeToggle == '' }"
        (click)="selectItemList('')"
      >
        All
      </a>
      <a
        class="nav-link"
        [ngClass]="{ active: activeToggle == 'Pending' }"
        (click)="selectItemList('Pending')"
      >
        Pending
      </a>
    </nav>
<div class="container-fluid p-24 " *ngIf="permissions">
  <div class="row mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Letters</h3>
      <p class="text-helper mb-md-0">{{title}}</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class="form-icon " style="width:22.6875rem;">
            <app-searchbar #search [searchlist]='searchlistdata' [searchplaceholder]='searchplaceholder' [isLoading]="sLoading"
              (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchemp($event)"></app-searchbar>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class=" btn-square btn btn-secondary" (click)="filterclick()" placement="bottom-left"
          id="dropdownBasic1" ngbDropdownToggle>
          <i class="icon-filter-left fs-13"></i>
          </button>
           <app-common-employee-filters *ngIf='filter === true' [filterForm]="filterForm" [from]="from" [filter]="filter"
            (filterClose)="closefn($event)"  (resetCalled)="resetCalledfn($event)" (filterOutput)="resultfilterfn($event)"></app-common-employee-filters>
        </div>
        <button class="btn flex-shrink-0 btn-primary btn-icon btn-add" (click)="addmiscletter()"><i
            class="icon-plus"></i>Generate Misc
          Letters</button>
      </div>
    </div>
  </div>
  <div class="row row-16 mb-16" *ngIf="activeToggle == 'Pending'">
    <div class="col-12">
      <div class="card card-c2">
        <div class="card-body p-24 d-flex gap-24 flex-wrap">
          <div class="statistic bg-primary">
            <span>Appointment Letter : </span> {{statusList?.appointment_letter}}
          </div>
          <div class="statistic bg-success">
            <span>Confirmation Letter : </span> {{statusList?.confirmation_letter}}
          </div>
          <!-- <div class="statistic bg-warning">
            <span>Extension Letter : </span> {{statusList?.extension_letter}}
          </div> -->
          <!-- <div class="statistic bg-info">
            <span>Miscellaneous Letter : </span> {{statusList?.miscellaneous_letter}}
          </div> -->
          <div class="statistic bg-warning">
            <span>Probation Extension Letter : </span> {{statusList?.probation_extension_letter}}
          </div>
          <div class="statistic bg-info">
            <span>Relieving Letter : </span> {{statusList?.relieving_letter}}
          </div>
          <div class="statistic bg-47">
            <span>Salary Revision Letter : </span> {{statusList?.salary_revision_letter}}
          </div>
          <div class="statistic bg-danger">
            <span>Termination  Letter : </span> {{statusList?.termination_letter}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row row-16 mb-16" *ngIf="loader">
    <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card4'"
      *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>
<div *ngIf="nodata && loader==false;else all_data" class="row " style="height: calc(100vh - 14rem);">
  <app-nodata-view *ngIf="activeToggle == 'Pending'" class="vstack" [noDataImg]="'bg2.png'" [noDataText]="'All Caught Up!'"
    [noDataPara]="'It seems there are no letters are waiting for action. Check back here for any new letters that might need your attention.'"
    [hasPermission]="permissions?.a" (addData)="addheadercontent($event)" [addtext]="'GENERATE NOW'"> </app-nodata-view>
  <app-nodata-view *ngIf="activeToggle == ''" class="vstack" [noDataImg]="'bg2.png'"
    [noDataText]="'All Caught Up!'"
    [noDataPara]="'There are no letters to view, download, or generate at the moment. Click the generate button to start creating new letters.'"
    [hasPermission]="permissions?.a" (addData)="addheadercontent($event)" [addtext]="'GENERATE NOW'"> </app-nodata-view>
</div>

  <ng-template #all_data>
   <div class="row row-16rem " infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
      [fromRoot]="true" (scrolled)="onScrollDown()">
      <ng-container *ngIf="loader==false">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let emplist of emplist let i = index">
          <div class="card card-custom1 card-hover">
            <div class="card-body p-0 d-flex align-items-start">

              <div class="d-flex view-section" (click)="viewletter(emplist?.id);viewDetails = true;">
                <img class="logo-img" src="{{emplist?.employee?.profile_image}}"   *ngIf="emplist?.employee?.profile_image !=  '' && emplist?.employee?.profile_image != null" />
                <div *ngIf="emplist?.employee?.profile_image ==  ''|| emplist?.employee?.profile_image == null" class="logo-img uniquebg1 {{emplist?.employee?.color_code}}">{{this.appService.getFirstChar(emplist?.employee?.first_name,2)}} </div>
                <div class="overflow-hidden">
                  <h6 class="mb-1 tips">{{emplist?.employee?.first_name }} {{(emplist?.employee?.middle_name)?emplist?.employee?.middle_name:'' }} {{emplist?.employee?.last_name }}</h6>
                  <span class="tips-helper">{{emplist?.employee?.first_name }} {{(emplist?.employee?.middle_name)?emplist?.employee?.middle_name:'' }} {{emplist?.employee?.last_name }}</span>
                  <div class="fs-10 text-uppercase fw-500 d-flex gap-6  ">
                    <span class="text-light-400">{{emplist?.employee?.employee_code}}</span>
                    <span class="text-light-400">|</span>
                    <span class="text-success" *ngIf="emplist?.status=='Published'">{{emplist?.status}}</span>
                    <span class="text-danger" *ngIf="emplist?.status=='Unpublished'">{{emplist?.status}}</span>
                    <span class="text-warning" *ngIf="emplist?.status=='Pending'">{{emplist?.status}}</span>
                  </div>
                </div>
              </div>
              <div ngbDropdown class="d-inline-block dropdown-section">
                <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                  <i class="icon-three-dots-vertical "></i>
                </button>
                <div ngbDropdownMenu class="bottom-left fs-14rem" aria-labelledby="dropdownBasic1">
                  <button ngbDropdownItem (click)="regenerate = true;viewletter(emplist?.id);" *ngIf="emplist?.status !='Unpublished' && emplist?.status!='Pending'">Regenerate
                  </button>
                  <button ngbDropdownItem (click)="regenerate = true;viewletter(emplist?.id);" *ngIf="emplist?.status=='Unpublished' || emplist?.status=='Pending'">Generate
                  </button>
                  <button ngbDropdownItem (click)="unpublishtoggle=true;letterid=emplist?.id" *ngIf="emplist?.status !='Unpublished' && emplist?.status!='Pending'">Unpublish
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body flex-column gap-8">
              <div class="d-flex justify-content-between w-100 gap-8  fs-10">
                <span class="text-light-400 text-nowrap text-uppercase">GENERATED BY </span>
                <span class=" text-trim" *ngIf="emplist?.status=='Published'"> {{emplist?.generated_by?.employee?.first_name}} {{(emplist?.generated_by?.employee?.middle_name)?emplist?.generated_by?.employee?.middle_name:''}} {{emplist?.generated_by?.employee?.last_name}}</span>
                <span class=" text-trim" *ngIf="emplist?.status!='Published'">--</span>
              </div>
              <div class="d-flex justify-content-between w-100 gap-8  fs-10">
                <span class="text-light-400 text-nowrap text-uppercase">GENERATED ON </span>
                <span class=" text-trim" *ngIf="emplist?.status=='Published'">{{this.appService.dateFormatDisplay(emplist?.generated_date)}}</span>
                <span class=" text-trim" *ngIf="emplist?.status!='Published'">--</span>
              </div>
              <div class="d-flex justify-content-between w-100 gap-8  fs-10">
                <span class="text-light-400 text-nowrap text-uppercase">LETTER TYPE </span>
                <span class=" text-trim">{{emplist?.letter_type}} </span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="infinityloader">
        <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card4'"
          *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
      </ng-container>
   </div>
  </ng-template>
</div>
</ng-container>

<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='generateMiscLetter === true'>
  <form [formGroup]="miscletterform" (ngSubmit)="onSubmit()" class="side-panel-container" autocomplete="off">
    <!-- <div class="side-panel-container" autocomplete="off"> -->
      <header class="side-panel-head">
        <h5>Generate Misc Letter </h5>
        <a class="toggle-panel" (click)="generateMiscLetter = false;forreset();"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;">
        <div class="container-fluid p-32">
          <div class="row row-16">
            <div class="col-12 d-flex">
              <p class="text-uppercase fw-500 mb-0">Send Letter</p>
            </div>
            <div class="col-12  form-row">
              <div class="form-check  ">
                <input type="radio" id="radio1" class="form-check-input" [value]="true"
                formControlName="is_specific_employee" (click)="changeApplicable();setRequired('company','employee')">
                <label for="radio1" class="form-check-label">Generate letter for specific employee(s) </label>
              </div>
            </div>
            <div class="col-12  form-row">
              <div class="form-check  ">
                <input type="radio" id="radio2" class="form-check-input" [value]="false"
                formControlName="is_specific_employee" (click)="changeApplicable();setRequired('employee','company')">
                <label for="radio2" class="form-check-label">Generate letter for employees organization wise </label>
              </div>
            </div>

          <ng-container *ngIf="f.is_specific_employee?.value == false">

              <div class="col-md-6">
                  <label for="" class="form-label required">Company </label>
                  <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Company')}}"
                      formControlName="company" [items]="companyDD" [multiple]="true"
                      groupBy="selectedAllGroup" [selectableGroup]="true"
                      [selectableGroupAsModel]="false" bindLabel="company_name"
                      [closeOnSelect]="false" bindValue="id" class="multiselect"
                      [ngClass]="{ 'is-invalid': (f.company.dirty || f.company.touched) &&  f.company.errors }"
                      (change)="getbulist(miscletterform.value.company)">
                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$"
                          let-index="index">
                          <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                              [ngModelOptions]="{standalone: true}" /> Select All
                      </ng-template>

                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                          <div class="ng-value" *ngIf="miscletterform.value.company?.length ">
                              <span class="ng-value-label">{{miscletterform.value.company?.length }}
                                  Company
                                  Selected</span>
                          </div>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          <div class="d-inline-flex align-items-center">
                              <input id="item-{{index}}" class="me-8" type="checkbox"
                                  [(ngModel)]="item$.selected"
                                  [ngModelOptions]="{ standalone : true }" />
                              <img class="avatar-sm sq-24 img-fluid rounded-circle"
                                  *ngIf="item.logo !=  null && item.logo !=  ''" src="{{item.logo}}">
                              <span *ngIf="item.logo ==  null || item.logo ==  ''"
                                  class="avatar-sm sq-24 me-8 rounded-circle bg{{index%5}}"><span>{{this.appService.getFirstChar(item.company_name,1)}}</span></span>
                              <span class="">{{ (item.company_name.length>15)? (item.company_name |
                                  slice:0:15)+'..':(item.company_name) }}</span>
                          </div>
                      </ng-template>
                  </ng-select>
                  <div *ngIf="(f.company.dirty || f.company.touched) && f.company.errors" class="invalid-feedback">
                    <div *ngIf="f.company.errors.required">Please select atleast one company.</div>
                  </div>
                  <ul class="selected-values mt-16 w-100" *ngIf="miscletterform.value.company?.length != 0">
                      <li class="ng-value"
                          *ngFor="let item of miscletterform.value.company,let ki =index">
                          <ng-container *ngFor="let insideitem of companyDD, let k =index">
                              <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                  <div class="d-inline-flex align-items-center">
                                      <img class=" img-fluid sq-24 rounded-circle"
                                          *ngIf="insideitem.logo !=  '' && insideitem.logo !=  null"
                                          src="{{insideitem.logo}}">
                                      <span *ngIf="insideitem.logo ==  null || insideitem.logo ==  ''"
                                          class="  sq-24 avatar-circle rounded-circle me-1 bg{{k%5}}">
                                          <span>
                                              {{this.appService.getFirstChar(insideitem.company_name,1)}}</span>
                                      </span>
                                      <span class=""> {{ (insideitem['company_name'].length>25)?
                                          (insideitem['company_name'] |
                                          slice:0:25)+'..':(insideitem['company_name']) }}</span>
                                  </div>
                              </span>
                          </ng-container>
                          <span *ngIf="disabled==false" class="ng-value-icon right"
                              (click)="clearcommonForm('company',ki)" aria-hidden="true">×</span>
                      </li>
                  </ul>
              </div>
              <div class="col-md-6">
                  <label for="" class="form-label">Business unit</label>
                  <ng-select [readonly]="miscletterform.value.company?.length == 0 ? true : false"
                      placeholder="{{this.messageService.selectplaceholddisplay('Business Unit')}}"
                      formControlName="businessunit" [items]="buDD" [multiple]="true" groupBy="selectedAllGroup"
                      [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="name"
                      [closeOnSelect]="false" bindValue="id" class="multiselect">
                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$"
                          let-index="index">
                          <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                              [ngModelOptions]="{standalone: true}" /> Select All
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          <div class="d-inline-flex align-items-center">
                              <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                  [ngModelOptions]="{ standalone : true }" class="me-8" />
                              {{ item.name }}
                          </div>
                      </ng-template>
                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                          <div class="ng-value" *ngIf="miscletterform.value.businessunit.length">
                              <span class="ng-value-label">{{miscletterform.value.businessunit.length}} Business
                                  Unit
                                  Selected</span>
                          </div>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-index="index">
                          {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                      </ng-template>
                  </ng-select>
                  <ul class="selected-values mt-16 w-100" *ngIf="miscletterform.value.businessunit?.length != 0 ">
                      <li class="ng-value" *ngFor="let item of miscletterform.value.businessunit,let ki =index">
                          <ng-container *ngFor="let insideitem of buDD, let k =index">
                              <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                  {{ (insideitem['name'].length>15)? (insideitem['name'] |
                                  slice:0:15)+'..':(insideitem['name']) }}</span>
                          </ng-container>
                          <span *ngIf="disabled==false" class="ng-value-icon right"
                              (click)="clearcommonForm('businessunit',ki)" aria-hidden="true">×</span>
                      </li>
                  </ul>

              </div>
              <div class="col-md-6">
                  <label for="" class="form-label">Department</label>
                  <ng-select
                      placeholder="{{this.messageService.selectplaceholddisplay('Department')}}"
                      formControlName="department" [items]="DepDD" [multiple]="true"
                      groupBy="selectedAllGroup" [selectableGroup]="true"
                      [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false"
                      bindValue="id" class="multiselect">
                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$"
                          let-index="index">
                          <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                              [ngModelOptions]="{standalone: true}" /> Select All
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          <div class="d-inline-flex align-items-center">
                              <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                  [ngModelOptions]="{ standalone : true }" class="me-8" />
                              {{ item.name }}
                          </div>
                      </ng-template>
                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                          <div class="ng-value" *ngIf="miscletterform.value.department?.length ">
                              <span class="ng-value-label">{{miscletterform.value.department?.length}}
                                  Department
                                  Selected</span>
                          </div>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-index="index">
                          {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                      </ng-template>
                  </ng-select>
                  <ul class="selected-values mt-16 w-100"
                      *ngIf="miscletterform.value.department?.length != 0">
                      <li class="ng-value"
                          *ngFor="let item of miscletterform.value.department,let ki =index">
                          <ng-container *ngFor="let insideitem of DepDD, let k =index">
                              <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                  {{ (insideitem['name'].length>15)? (insideitem['name'] |
                                  slice:0:15)+'..':(insideitem['name']) }}</span>
                          </ng-container>
                          <span *ngIf="disabled==false" class="ng-value-icon right"
                              (click)="clearcommonForm('department',ki)" aria-hidden="true">×</span>
                      </li>
                  </ul>
              </div>
              <div class="col-md-6">
                  <label for="" class="form-label">Designation</label>
                  <ng-select
                      placeholder="{{this.messageService.selectplaceholddisplay('Designation')}}"
                      formControlName="designation" [items]="DesignationDD" [multiple]="true"
                      groupBy="selectedAllGroup" [selectableGroup]="true"
                      [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false"
                      bindValue="id" class="multiselect">
                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$"
                          let-index="index">
                          <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                              [ngModelOptions]="{standalone: true}" /> Select All
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          <div class="d-inline-flex align-items-center">
                              <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                  [ngModelOptions]="{ standalone : true }" class="me-8" />
                              {{ item.name }}
                          </div>
                      </ng-template>
                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                          <div class="ng-value" *ngIf="miscletterform.value.designation?.length">
                              <span class="ng-value-label">{{miscletterform.value.designation?.length}}
                                  Designation
                                  Selected</span>
                          </div>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-index="index">
                          {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                      </ng-template>
                  </ng-select>
                  <ul class="selected-values mt-16 w-100"
                      *ngIf="miscletterform.value.designation?.length != 0">
                      <li class="ng-value"
                          *ngFor="let item of miscletterform.value.designation,let ki =index">
                          <ng-container *ngFor="let insideitem of DesignationDD, let k =index">
                              <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                  {{ (insideitem['name'].length>15)? (insideitem['name'] |
                                  slice:0:15)+'..':(insideitem['name']) }}</span>
                          </ng-container>
                          <span *ngIf="disabled==false" class="ng-value-icon right"
                              (click)="clearcommonForm('designation',ki)" aria-hidden="true">×</span>
                      </li>
                  </ul>
              </div>
              <div class="col-md-6">
                  <label for="" class="form-label">Branch</label>
                  <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Branch')}}"
                      formControlName="branch" [items]="BranchDD" [multiple]="true"
                      groupBy="selectedAllGroup" [selectableGroup]="true"
                      [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false"
                      bindValue="id" class="multiselect">
                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$"
                          let-index="index">
                          <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                              [ngModelOptions]="{standalone: true}" /> Select All
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          <div class="d-inline-flex align-items-center">
                              <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                  [ngModelOptions]="{ standalone : true }" class="me-8" />
                              {{ item.name }}
                          </div>
                      </ng-template>
                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                          <div class="ng-value" *ngIf="miscletterform.value.branch?.length ">
                              <span class="ng-value-label">{{miscletterform.value.branch?.length }}
                                  Branch
                                  Selected.</span>
                          </div>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-index="index">
                          {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                      </ng-template>
                  </ng-select>
                  <ul class="selected-values mt-16 w-100" *ngIf="miscletterform.value.branch?.length != 0">
                      <li class="ng-value"
                          *ngFor="let item of miscletterform.value.branch,let ki =index">
                          <ng-container *ngFor="let insideitem of BranchDD, let k =index">
                              <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                  {{ (insideitem['name'].length>15)? (insideitem['name'] |
                                  slice:0:15)+'..':(insideitem['name']) }}</span>
                          </ng-container>
                          <span *ngIf="disabled==false" class="ng-value-icon right"
                              (click)="clearcommonForm('branch',ki)" aria-hidden="true">×</span>
                      </li>
                  </ul>
              </div>
              <div class="col-md-6">
                  <label for="" class="form-label">Grade</label>
                  <ng-select placeholder="{{this.messageService.selectplaceholddisplay('Grade')}}"
                      formControlName="grade" [items]="gradeDD" [multiple]="true"
                      groupBy="selectedAllGroup" [selectableGroup]="true"
                      [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false"
                      bindValue="id" class="multiselect">
                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$"
                          let-index="index">
                          <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                              [ngModelOptions]="{standalone: true}" /> Select All
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          <div class="d-inline-flex align-items-center">
                              <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                                  [ngModelOptions]="{ standalone : true }" class="me-8" />
                              {{ (item.name.length>15)? (item.name | slice:0:15)+'..':(item.name) }}
                          </div>
                      </ng-template>
                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                          <div class="ng-value" *ngIf="miscletterform.value.grade?.length ">
                              <span class="ng-value-label">{{miscletterform.value?.grade?.length }}
                                  Grade
                                  Selected.</span>
                          </div>
                      </ng-template>
                  </ng-select>
                  <ul class="selected-values mt-16 w-100" *ngIf="miscletterform.value.grade?.length != 0">
                      <li class="ng-value"
                          *ngFor="let item of miscletterform.value.grade,let ki =index">
                          <ng-container *ngFor="let insideitem of gradeDD, let k =index">
                              <span class="ng-value-label" *ngIf="insideitem['id']==item">
                                  {{ (insideitem['name'].length>15)? (insideitem['name'] |
                                  slice:0:15)+'..':(insideitem['name']) }}</span>
                          </ng-container>
                          <span *ngIf="disabled==false" class="ng-value-icon right"
                              (click)="clearcommonForm('grade',ki)" aria-hidden="true">×</span>
                      </li>
                  </ul>
              </div>
          </ng-container>
          <ng-container *ngIf="f.is_specific_employee?.value == true">
              <div class="col-12">
                  <label for="" class="form-label required">Search by name or code </label>
                  <ng-select placeholder="{{this.messageService.selectplaceholddisplay('employee')}}"
                      formControlName="employee" [items]="employeeData" [multiple]="true"
                      groupBy="selectedAllGroup" [selectableGroup]="true"
                      [ngClass]="{ 'is-invalid': (f.employee.dirty || f.employee.touched) &&  f.employee.errors }"
                      [selectableGroupAsModel]="false" bindLabel="name" [closeOnSelect]="false"
                      bindValue="id" class="multiselect">
                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$"
                          let-index="index">
                          <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                              [ngModelOptions]="{standalone: true}" /> Select All
                      </ng-template>
                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                          <div class="ng-value" *ngIf="miscletterform.value.employee?.length">
                              <span class="ng-value-label">{{miscletterform.value.employee?.length}}
                                  Employee
                                  selected.</span>
                          </div>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          <div class="d-inline-flex"> <input id="item-{{index}}" class="me-8"
                                  type="checkbox" [(ngModel)]="item$.selected"
                                  [ngModelOptions]="{ standalone : true }" /><img
                                  class="avatar-sm sq-24 img-fluid rounded-circle"
                                  *ngIf="item.profile_image !=  null && item.profile_image !=  ''"
                                  src="{{item.profile_image}}">
                              <span *ngIf="item.profile_image ==  null || item.profile_image ==  ''"
                                  class="avatar-sm sq-24 me-8 rounded-circle {{item.color_code}}"><span>{{this.appService.getFirstChar(item.name,1)}}</span></span>
                              <span class=""> {{ (item.name.length>30)? (item.name | slice:0:30)+'..':(item.name)  }}</span>
                              <!-- <span class="mt-1 ms-1">{{ (item.name.length>25)? (item.name |
                                  slice:0:30)+'..':(item.name) }}</span> -->
                          </div>
                      </ng-template>
                  </ng-select>
                  <div *ngIf="(f.employee.touched || f.employee.dirty) && f.employee.errors" class="invalid-feedback">
                    <div *ngIf="f.employee.errors.required">Please select atleast one employee.</div>
                  </div>
              </div>
              <div class="col-12" *ngIf="miscletterform.value.employee?.length !=0">
                <ul class="selected-values" >
                  <li class="ng-value"
                      *ngFor="let item of miscletterform.value.employee, let m =index">
                      <ng-container *ngFor="let insideitem of employeeData, let k =index">
                          <span class="ng-value-label" *ngIf="insideitem['id']==item">
                              <div class="d-inline-flex align-items-center">
                                  <img class="avatar-sm sq-24 img-fluid rounded-circle"
                                      *ngIf="insideitem.profile_image !=  '' && insideitem.profile_image !=  null"
                                      src="{{insideitem.profile_image}}">
                                  <span
                                      *ngIf="insideitem.profile_image ==  null || insideitem.profile_image ==  ''"
                                      class="avatar-sm sq-24 rounded-circle {{insideitem.color_code}}">
                                      <span>
                                          {{this.appService.getFirstChar(insideitem.first_name+"
                                          "+insideitem.last_name,2)}}</span>
                                  </span>
                                  <span class="ms-1">
                                      {{insideitem.first_name+"
                                      "+(insideitem.middle_name?insideitem.middle_name+" ":"
                                      ")+insideitem.last_name +" ("+insideitem.employee_code+")"}}
                                  </span>
                              </div>
                          </span>
                      </ng-container>
                      <span *ngIf="disabled==false" class="ng-value-icon right" aria-hidden="true"
                          (click)="clearcommonForm('employee',m)"><i
                              class="icon-close"></i></span>
                  </li>
              </ul>
              </div>

          </ng-container>

            <div class="col-12 d-flex">
              <p class="text-uppercase fw-500 mb-0">Letter template</p>
            </div>
            <div class="col-12">
              <label for="" class="form-label required">Template </label>
              <ng-select class="form-ngselect"
                                (change)="getlettertemplatdetail()"
                                formControlName="letter_template"
                                [ngClass]="{ 'is-invalid': (submitted || f.letter_template.touched) &&  f.letter_template.errors }"
                                name=""  id="letter_template"
                                placeholder="{{messageService.selectplaceholddisplay('letter template')}}"  [clearable]="false">
                        <ng-option *ngFor="let letter_template of templatelist"  [value]="letter_template.id">{{letter_template.name}}</ng-option>
              </ng-select>
                        <div *ngIf="(submitted || f.letter_template.touched) && f.letter_template.errors" class="invalid-feedback">
                            <div *ngIf="f.letter_template.errors.required">{{this.messageService.validationDisplay('required')}}</div>
                        </div>
            </div>

            <div class="link-primary1 fs-14 d-flex align-items-center" (click)="viewDocument(templatedata?.pdf_url,templatedata?.file_name)" *ngIf="f.letter_template?.value">
              <i class="icon-eye fs-16 me-8" ></i>Preview Template
            </div>
            <div class="col-12">
              <label for="" class="form-label required">Remarks </label>
              <input type="text" name="" class="form-control" formControlName="remarks" placeholder="Enter Remarks " id="" [ngClass]="{ 'is-invalid': (submitted || f.remarks.touched || f.remarks.dirty) &&  f.remarks.errors }">
              <div *ngIf="(submitted || f.remarks.touched || f.remarks.dirty) && f.remarks.errors" class="invalid-feedback">
                <div *ngIf="f.remarks.errors.required">{{this.messageService.validationDisplay('required')}}
                </div>
                <div *ngIf="f.remarks.errors.pattern">{{this.messageService.validationDisplay('pattern')}}
                </div>
                <div *ngIf="!(f.remarks.errors.pattern) && f.remarks.errors.maxlength">
                  {{this.messageService.fieldlengthvalidation('word','500')}}
                </div>
              </div>
            </div>
            <!-- <div class="col-12">
              <label for="check" class="form-check p-0 d-flex ">
                <input type="checkbox" name="check" id="check " class="form-check-input ms-0">
                <label for="check " class="form-check-label fs-16">Do you want yo share letter on email id?</label>
              </label>
            </div>
            <div class="col-12">
              <label for="" class="form-label required"> Email ID</label>
              <input type="text" name="" class="form-control" placeholder="Enter Email ID" id="">
            </div> -->
          </div>
        </div>
      </div>
      <footer class="side-panel-footer">
        <button type="submit" class="ms-auto btn btn-primary text-uppercase btn-sm" [disabled]='disabled || this.miscletterform.invalid'>
          Send Letter
        </button>
      </footer>
    <!-- </div> -->
  </form>
</div>

<div class="side-panel side-pane-active" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='viewDetails === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5> Details </h5>
      <a class="toggle-panel" (click)="viewDetails = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;" *ngIf="permissions">
      <div class="container-fluid p-32">
        <app-view-loader *ngIf="loader1"></app-view-loader>
        <ng-container *ngIf="!loader1">
        <div class="row row-16">
          <div class="col-12 d-flex">
            <p class="text-uppercase fw-500 mb-0 me-1"> {{Viewdata?.employee?.first_name}} {{Viewdata?.employee?.middle_name?Viewdata?.employee?.middle_name:''}}  {{Viewdata?.employee?.last_name}} ({{Viewdata?.employee?.employee_code}})</p>
            <div class="ms-auto d-flex fs-14 gap-8 text-nowrap" *ngIf="Viewdata?.letter_pdf_url && Viewdata?.status!='Pending'">
              <!-- this.appService.downloadFile(Viewdata?.letter_pdf_url,this.appService.getDocName(Viewdata?.letter_pdf_url)) -->
              <a class="link-primary1" (click)="downLoad(Viewdata?.letter_pdf_url)"><i class="icon-download me-1"></i> Download</a>
              <a class="link-primary1" (click)="viewDocument(Viewdata?.letter_pdf_url,Viewdata?.file_name)"><i class="icon-eye me-1"></i> View</a>
            </div>
          </div>
          <div class="col-12">
            <div class="card details-card shadow-0 px-24 pb-24 pt-16">
              <div class="row row-16 ">
                <div class="col-12 align-items-center d-flex">
                  <p class="title mb-8">Letter Details</p>
                  <span  *ngIf="Viewdata?.status=='Published'" class="badge badge-success ms-auto py-1 px-3"> Published</span>
                  <span  *ngIf="Viewdata?.status=='Pending'" class="badge badge-warning ms-auto py-1 px-3"> Pending</span>
                  <span  *ngIf="Viewdata?.status=='Unpublished'" class="badge badge-danger ms-auto py-1 px-3"> Unpublished</span>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Generated By</div>
                  <div class="detail-desc" *ngIf="Viewdata?.status=='Published'">{{Viewdata?.generated_by?.employee?.first_name}} {{Viewdata?.generated_by?.employee?.middle_name?Viewdata?.generated_by?.employee?.middle_name:''}} {{Viewdata?.generated_by?.employee?.last_name}}</div>
                  <div class="detail-desc" *ngIf="Viewdata?.status!='Published'">--</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Letter Type</div>
                  <div class="detail-desc">{{(Viewdata?.letter_type)?(Viewdata?.letter_type):'--'}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Generated On</div>
                  <div class="detail-desc" *ngIf="Viewdata?.status=='Published'">{{(Viewdata?.generated_date)?(this.appService.dateFormatDisplay(Viewdata?.generated_date)):'--'}}</div>
                  <div class="detail-desc" *ngIf="Viewdata?.status!='Published'">--</div>
                </div>
                <div class="col-12" *ngIf="Viewdata?.letter?.is_send_letter">
                  <div class="detail-title">Letter send to</div>
                  <div class="detail-desc">{{(Viewdata?.letter?.email)?(Viewdata?.letter?.email):'--'}}</div>
                </div>
                <div class=" col-12">
                  <div class="detail-title">Remarks</div>
                  <div class="detail-desc">{{(Viewdata?.remarks)?(Viewdata?.remarks):'--'}}</div>
                </div>

              </div>
            </div>
          </div>
        </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<app-view-file *ngIf="modalToggle== true" (modal)="close($event)" [modalToggle]="modalToggle"
    [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName">
</app-view-file>

<div class="modal  modal-alert {{unpublishtoggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Unpublish</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">Unpublishing will remove the content and it will no longer be available. Are you sure you want to unpublish?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-sm text-uppercase" (click)="unpublish(letterid);">Yes</button>
          <button type="button" class="btn btn-outline-primary btn-sm text-uppercase" (click)="unpublishtoggle = false">No</button>
      </div>
    </div>
  </div>
</div>

<app-generate-letter *ngIf="regenerate== true" [regenerate]="regenerate" (panelclose)="closegen($event)" (regenerateop)="regeneratefn($event)" [Viewdata]="Viewdata" [loader1]="loader1" [from]="'emp'">
</app-generate-letter>

